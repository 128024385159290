export default {
  data() {
   return {
     pageInfo: {
       total: 0,
       pageNum: 1,
       pageSize: 10,
     },
   }
  },
  methods: {
    // 索引问题
    indexMethod(index) {
      return (index + 1) + (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize;
    },
  }
}